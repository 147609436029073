<template>
  <div class="cms-header">
    <div class="r_bar">
      <div class="width_w clearfix">
<!--        <ul class="cms-header-hello left">-->
<!--          <li><a href="#">您好，欢迎关注和使用荷云采！</a></li>-->
<!--        </ul>-->
        <ul class="yqlj right">
          <li v-if="!userLoginFlag">
            <a :href="this.memberUrl">登录</a>
          </li>
          <li  v-if="!userLoginFlag">
            <a :href="this.memberRegisterUrl">注册</a>
          </li>
          <li  v-if="userLoginFlag">
            <a :href="this.memberThree+'/ebidding/#/login'">欢迎您，{{ userInfo.trueName}}</a>
          </li>
          <li  v-if="userLoginFlag">
            <a :href="this.memberThree+'/ebidding/#/login'">进入后台</a>
          </li>
          <li  v-if="userLoginFlag && userInfo.isSyncVc =='1'">
            <a :href="this.member+'/member/login/ruoyiBlank.htm'">数字化采购专区</a>
          </li>
          <li style="margin-left: 40px">
            <router-link :to="{path:'/zone-home'}">返回平台</router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {getUserInfo} from "../../api/commoon/user";

export default {
  name: "zone-header",
  props: ["companyId", "id"],
  methods: {
    //判断用户是否登入
    checkUser: function (uuid) {
      var that = this;
      getUserInfo(that.member).then(function (res) {
        that.userInfo = res.data.data;
        if (that.userInfo.isLogin =='1' ) {
          that["userLoginFlag"] = true;
        }else {
          that["userLoginFlag"] = false;
        }
      }).catch(function (err) {
        console.log(err);
      });

    },
    //从cookie获取值
    getCookie: function (name) {
      var that = this;
      var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
      if (arr = document.cookie.match(reg)) {
        return (arr[2]);
      } else
        return null;
    },
  },
  data() {
    return {
      userInfo: {},
      userLoginFlag: false,
      memberUrl:'',
      memberRegisterUrl:''
    }
  }, created() {
    this.checkUser(this.getCookie("uuid"));

    if(this.$route.query.id!=null && this.$route.query.id!=""){
      this.memberUrl=this.memberThree + "/ebidding/#/login?redirect=%2Fmine%2Fmine";
      this.memberRegisterUrl=this.memberThree + '/ebidding/#/ls/account';
    }else{
      this.memberUrl=this.member + "/member/login/executeLogin.htm";
      this.memberRegisterUrl=this.member + '/member/sysreg/register.htm?isAudit=1&no_sitemesh';

    }
  },
}
</script>
<style scoped lang="css" src="../../assets/commoon/css/common.css"></style>

<style scoped>
.cms-container {
  margin: 0 auto;
  width: 1160px;
  height: 100%;
}
.cms-header-hello{
  margin-bottom: 0;
}
.yqlj{
  margin: 0px;
  margin-right:10px
}

.cms-header {
  width: 100%;
  background: #FFFFFF;
}

.cms-top {
  width: 100%;
  height: 23px;

}

.cms-hello {
  height: 16px;
  font-size: 1.2rem;
  font-family: MicrosoftYaHei;
  color: #333333;
}

.cms-login {
  height: 16px;
  font-size: 1.2rem;
  font-family: MicrosoftYaHei;
  color: #333333;
  margin-right: 42px;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .cms-container {
    margin: 0 auto;
    width: 100%;
    height: 100%;
  }

  .cms-header {
    width: 100%;
    height: 100%;
    background: #FFFFFF;
  }

  .cms-hello {
    font-family: MicrosoftYaHei;
    color: #333333;
  }

  .cms-login {
    font-family: MicrosoftYaHei;
    color: #333333;
    margin-right: 34px;
  }
}


@media (min-width: 768px) and (max-width: 992px) {
  .cms-container {
    margin: 0 auto;
    width: 100%;
    height: 100%;
  }

  .cms-header {
    width: 100%;
    height: 100%;
    background: #FFFFFF;
  }

  .cms-hello {
    height: 14px;
    font-size: 1rem;
    font-family: MicrosoftYaHei;
    color: #333333;
  }

  .cms-login {
    height: 14px;
    font-family: MicrosoftYaHei;
    color: #333333;
    margin-right: 34px;
  }


  .cms-link {
    height: 16px;
    font-size: 1rem;
    font-family: MicrosoftYaHei;
    color: #333333;
    padding-right: 15px;
  }
}


@media (min-width: 576px ) and (max-width: 768px) {
  .cms-container {
    margin: 0 auto;
    width: 100%;
    height: 100%;
  }

  .cms-header {
    width: 100%;
    background: #FFFFFF;
  }

  .cms-hello {
    height: 13px;
    font-family: MicrosoftYaHei;
    color: #333333;
  }

  .cms-login {
    height: 13px;
    font-family: MicrosoftYaHei;
    color: #333333;
    margin-right: 0px;
    padding-right: 10px;
  }

  .cms-link {
    height: 13px;
    font-family: MicrosoftYaHei;
    color: #333333;
  }
}

@media screen and (max-width: 576px) {
  .cms-container {
    margin: 0 auto;
    width: 100%;
    height: 100%;
  }

  .cms-header {
    width: 100%;
    background: #FFFFFF;
  }

  .cms-hello {
    height: 13px;
    font-family: MicrosoftYaHei;
    color: #333333;
  }

  .cms-header-content {
    margin-top: 10px;
    width: 100%;
  }

  .cms-login {
    height: 13px;
    font-family: MicrosoftYaHei;
    color: #333333;
    margin-right: 0px;
    padding-right: 10px;
  }

  .cms-link {
    height: 13px;
    font-family: MicrosoftYaHei;
    color: #333333;
  }
}
</style>
